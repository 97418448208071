import { graphql } from "gatsby";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Slider from "react-slick";
import BlogCard from "../components/BlogCard";
import {
  HeadingGradientForLearn,
  LearnSliderComponent,
} from "../components/LearningHubComponents";
import NewHomeLeadFormWithAPIForm from "../components/NewHomeLeadFormWithAPI";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { blogSliderSettings } from "../constants";
import "../styles/page/learn_page.scss";
import { sanatizeSearchText } from "../utilities/helpers";

const LearnHeaderComponent = ({ pageHeading, pageDescription }) => {
  return (
    <article className="bg-[#F8F8FF] pt-32 md:pt-36 mb-8">
      <section className=" w-full mx-auto flex flex-col items-center text-center px-2.5 md:px-0">
      <span className="uppercase font-manrope text-xs sm:text-base tracking-[1.2px] sm:tracking-[1.6px] font-medium text-purple-1500">Resources</span>
        <span className="pt-3">
          <HeadingGradientForLearn headingHTML={pageHeading?.html} />
        </span>
        <span className="pt-5 pb-12 text-base max-w-[288px] sm:max-w-[648px] lg:max-w-[972px] font-normal sm:text-lg text-gray-600 ">
          {pageDescription?.text}
        </span>
      </section>
    </article>
  );
};

const Learn = ({ data, location }) => {
  const [searchText, setSearchText] = useState("");
  const _data = data?.prismicLearningHub?.data || {};
  const learning_hub_list =
    _data?.body?.filter((x) => x.slice_type === "learning_hub_list") || [];
  const [learningHubList, setLearningHubList] = useState(learning_hub_list);

  useEffect(() => {
    const regex = new RegExp(sanatizeSearchText(searchText), "iu");
    const filteredLearningHubList = learning_hub_list.map((x) => ({
      ...x,
      items: x?.items?.filter(
        (item) => item?.card_heading?.text?.match(regex)?.length
      ),
    }));
    setLearningHubList([...filteredLearningHubList]);
  }, [searchText]);

  return (
    <Layout>
      <Helmet>
        <link
          rel="stylesheet"
          type="text/css"
          charset="UTF-8"
          hreflang="en"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          hreflang="en"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
        />
      </Helmet>
      <section className="">
        <LearnHeaderComponent
          pageHeading={_data?.page_heading}
          pageDescription={_data?.page_description}
        />
        {[...learningHubList]?.map((item, index) => {
          return (
            <React.Fragment key={item?.id}>
              <LearnSliderComponent
                learning_hub_item={item}
                isSearch={index === 0}
                searchText={searchText}
                setSearchText={setSearchText}
                isDivider
              />
            </React.Fragment>
          );
        })}
        <article className="w-full max-w-xs sm:max-w-xl pb-4 mx-auto lg:max-w-7xl md:max-w-2xl">
          <section className="pt-5 pb-11">
            <div className="flex flex-col justify-between md:flex-row">
              <div className="flex flex-col ml-2.5">
                <div className="text-xl font-semibold text-purple-1000 md:text-2xl">
                  Papers explained
                </div>

                <div className="text-base font-normal text-purple-1000">
                  Read detailed explainers of cutting edge research papers we
                  like at Encord and implement in our platform.
                </div>
              </div>
            </div>
            <Slider
              {...blogSliderSettings}
              slidesToShow={4}
              className="learn-blog-slider"
            >
              {data.allPrismicBlog &&
                !!data.allPrismicBlog?.edges.length &&
                data.allPrismicBlog?.edges
                  .filter((_blog) => _blog.node.uid !== data?.prismicBlog?.uid)
                  .slice(0, 6)
                  .map((blog, index) => {
                    return (
                      <BlogCard
                        allowSubDetails={false}
                        searchText={""}
                        location={location}
                        key={`blog_card_${index}`}
                        blog={blog}
                      />
                    );
                  })}
            </Slider>
          </section>
        </article>

        <div
          onClick={() => {
            window[`scrollTo`]({ top: 0, behavior: `smooth` });
          }}
          className="flex justify-center mb-8 text-xl font-normal underline cursor-pointer"
        >
          Back to top
        </div>
        <article className="py-20 gradiant_bg lg:py-32">
          <section className="w-full max-w-xl mx-auto text-center">
            <h6 className="text-2xl sm:text-3.5xl font-semibold mb-5 text-white">
              {_data?.cta_header?.text}
            </h6>
            <p className="mb-5 text-base text-white">
              {_data?.cta_description?.text}
            </p>

            <section className="flex justify-center">
              <NewHomeLeadFormWithAPIForm
                pageName="New Landing Page"
                location={location}
                btnText="Book a demo"
              />
            </section>
          </section>
        </article>
      </section>
    </Layout>
  );
};

/**
 * Page query for the Learning Hub Page
 */
export const query = graphql`
  {
    allPrismicBlog(
      sort: { first_publication_date: DESC }
      filter: {
        uid: {
          in: [
            "imagebind-embedding-model-explained"
            "dinov2-self-supervised-learning-explained"
            "seggpt-segment-everything-in-context-explainer"
            "segment-anything-model-explained"
          ]
        }
      }
    ) {
      edges {
        node {
          uid
          data {
            author {
              document {
                ... on PrismicAuthor {
                  uid
                  data {
                    full_name {
                      text
                    }
                  }
                }
              }
            }
            content {
              html
            }
            body {
              ... on PrismicBlogDataBodyTable {
                id
                primary {
                  table_title {
                    text
                  }
                }
                items {
                  col1 {
                    text
                    html
                  }
                  col2 {
                    text
                    html
                  }
                  col3 {
                    text
                    html
                  }
                  col4 {
                    text
                    html
                  }
                }
              }
            }
            image {
              url
              gatsbyImageData(width: 400, placeholder: BLURRED)
            }
            sub_image {
              url
              gatsbyImageData(width: 400, placeholder: BLURRED)
            }
            title {
              text
            }
            read_time
          }
          first_publication_date
          tags
        }
      }
    }
    prismicLearningHub {
      data {
        body {
          ... on PrismicLearningHubDataBodyLearningHubList {
            id
            slice_type
            items {
              card_description {
                html
                text
              }
              card_heading {
                html
                text
              }
              tag {
                html
                text
              }
              thumbnail {
                url
              }
              video_time {
                text
              }
              embedded_iframe {
                html
              }
              slug {
                text
              }
            }
            primary {
              description {
                text
              }
              heading {
                html
                text
              }
            }
          }
        }
        meta_description {
          text
        }
        meta_heading {
          text
        }
        page_description {
          html
          text
        }
        page_heading {
          html
          text
        }
        cta_header {
          html
          text
        }
        cta_description {
          html
          text
        }
      }
    }
  }
`;

/**
 * SEO hearder
 * @returns JSX.Element
 */
export const Head = ({ data }) => {
  const _data = data?.prismicLearningHub?.data || {};
  return (
    <SEO
      title={ _data?.meta_heading?.text}
      description={_data?.meta_description?.text}
      image="https://images.prismic.io/encord/295ddcea-8ef9-4d08-94f0-e82113bb491e_Learning+Hub+page.png?auto=compress,format"
      url="https://encord.com/learning-hub/"
    />
  );
};

export default Learn;
